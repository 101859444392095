'use client';

import {SearchResponse} from '@/types/search';
import {ProductCategory, ProductSearchItem} from '@/types/objects/product';
import {useMemo, useRef, useState} from 'react';
import FilterOverlay from '@/app/components/search/filter/FilterOverlay';
import useSearch from '@/hooks/search';
import FilterChips from '@/app/components/search/filter/FilterChips';
import ProductCategoryFilter from '@/app/components/product/overview/ProductCategoryFilter';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import FilterHelper from '@/app/components/search/filter/FilterHelper';
import useTranslations from '@/hooks/translations';
import ProductItem from '@/app/components/product/ProductItem';
import ProductItemSkeleton from '@/app/components/product/ProductItemSkeleton';
import NoItemsFound from '@/app/components/search/NoItemsFound';

interface ProductOverviewListProps {
    limit: number;
    order: 'asc' | 'desc';
    orderKey: string;
    initialSearchResponse: SearchResponse<ProductSearchItem>;
    categories: ProductCategory[];
    activeCategoryId?: number;
}

export default function ProductOverviewList({
                                                limit,
                                                orderKey,
                                                order,
                                                categories,
                                                initialSearchResponse,
                                                activeCategoryId
                                            }: ProductOverviewListProps) {
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);
    const filterFormRef = useRef<HTMLFormElement | null>(null);
    const {tc} = useTranslations();

    const {
        items,
        loading,
        handlePagination,
        handleReset,
        handleFilter,
        filterOpen,
        setFilterOpen,
        chips,
        handleChipRemove,
        totalCount,
        aggregations,
    } = useSearch<ProductSearchItem>(
        'productSearch',
        initialSearchResponse.hits,
        initialSearchResponse.aggregations || [],
        filterFormRef,
        initialSearchResponse.totalCount || 0,
        limit,
        order,
        orderKey,
        ['category'],
    );

    const additionalSearchQuery: string = useMemo(() => activeCategoryId ? `&preFilter[category]=${activeCategoryId}` : '', [activeCategoryId]);

    const onFilter = (closeFilter: boolean) => {
        handleFilter(closeFilter, additionalSearchQuery);
        setInitialLoaded(true);
    }

    return (
        <div className="toolbox-element toolbox-product-overview">
            <ClientOnly>
                <FilterHelper formRef={filterFormRef}
                              handleFilter={() => onFilter(false)}/>
            </ClientOnly>
            {categories?.length ? (
                <div className="relative hidden lg:block">
                    <div className="h-1/2 absolute inset-0 w-full bg-grey-100"></div>
                    <div className="container relative">
                        <ProductCategoryFilter categories={categories}
                                               activeCategoryId={activeCategoryId} />
                    </div>
                </div>
            ) : <></>}
            <div className="container mt-12">
                <div
                    className={`grid grid-cols-1 ${aggregations?.length ? 'gap-12 lg:grid-cols-4' : ''}`}>
                    {((!initialLoaded && initialSearchResponse.aggregations?.length) || aggregations?.length) ? (
                        <form noValidate
                              ref={filterFormRef}
                              onChange={() => onFilter(false)}
                              onSubmit={(e) => e.preventDefault()}>
                            <FilterOverlay handleFilter={(closeFilter: boolean) => onFilter(closeFilter)}
                                           filterOpen={filterOpen}
                                           setFilterOpen={setFilterOpen}
                                           searchFilterChips={chips}
                                           buttonText={'app.search.product_search.open_filter'}
                                           title={'app.search.product_search.filter_title'}
                                           aggregations={aggregations || []}
                                           additionalSearchQuery={additionalSearchQuery}
                                           handleReset={handleReset}>
                                <ProductCategoryFilter categories={categories}
                                                       activeCategoryId={activeCategoryId} />
                            </FilterOverlay>
                        </form>
                    ) : <></>}
                    <div className="lg:col-span-3">
                        <FilterChips chips={chips}
                                     removeChip={handleChipRemove}
                                     loading={loading}
                                     additionalSearchQuery={additionalSearchQuery}/>
                        {loading ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
                                {[...Array(limit)].map((_, index) => (
                                    <ProductItemSkeleton key={index}/>
                                ))}
                            </div>
                        ) : totalCount === 0 ? (
                            <NoItemsFound title={'app.product.search.no_products_found'}/>
                        ) : (
                            <div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
                                    {items.map((item) => (
                                        <ProductItem data={item} key={item.id}/>
                                    ))}
                                </div>
                                {totalCount > items.length ? (
                                    <div className="justify-center flex mt-6">
                                        <button className="btn underline text-primary-text"
                                                type="button"
                                                onClick={() => handlePagination(additionalSearchQuery)}
                                                disabled={loading}>
                                            {tc('app.search.load_more')}
                                        </button>
                                    </div>
                                ) : <></>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
