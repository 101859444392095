'use client';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import {GalleryImage} from '@/app/components/cms/content/Gallery/Gallery.types';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import React, {useState} from 'react';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import Lightbox from '@/app/components/util/Lightbox/Lightbox';
import SliderButtons from '@/app/components/util/Slider/SliderButtons';

interface GallerySliderProps {
    images: Array<GalleryImage>;
    withLightbox?: boolean;
    forceSliderButtons?: boolean;
    startSlide?: number;
}

export default function GallerySlider({images, startSlide, withLightbox, forceSliderButtons}: GallerySliderProps) {
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
    const [lightboxImage, setLightboxImage] = useState<number>(-1);

    const openLightbox = (index: number) => {
        setLightboxImage(index);
        setLightboxOpen(true);
    }

    const closeLightbox = () => {
        setLightboxImage(-1);
        setLightboxOpen(false);
    }

    const hasDescriptions = images.some(image => !!image.image.title);

    return (
        <>
            {images?.length > 0 && (
                <>
                    <div className="relative w-full">
                        <Swiper spaceBetween={12}
                                slidesPerView={1}
                                initialSlide={startSlide || 0}
                                loop={true}
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className={`w-full h-full gallery-slider ${hasDescriptions && 'has-descriptions'}`}>

                            {(withLightbox || forceSliderButtons) ? (
                                <SliderButtons hasDescriptions={hasDescriptions} withLightbox={withLightbox}/>
                            ) : <></>}

                            {images.map((image, index) => (
                                <SwiperSlide key={index}
                                             onClick={() => withLightbox ? openLightbox(index) : null}>
                                    <PimcoreImage
                                        image={image.image}
                                        className="aspect-[16/9] h-auto w-full rounded-lg object-cover object-center"
                                    />

                                    {!!image.image.title && (
                                        <span className="mt-2 block text-description text-grey-400">
                                            {image.image.title}
                                        </span>
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    {withLightbox && (
                        <ClientOnly>
                            <Lightbox open={lightboxOpen} setOpen={closeLightbox}>
                                <div className="flex h-full w-full items-center justify-center">
                                    <div className="container flex max-h-4/5 max-w-4xl">
                                        <GallerySlider
                                            images={images}
                                            withLightbox={false}
                                            startSlide={lightboxImage}
                                        />
                                    </div>
                                </div>
                            </Lightbox>
                        </ClientOnly>
                    )}
                </>
            )}
        </>
    )
}
