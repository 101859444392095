import {SearchAggregation} from '@/types/search';
import React, {useState} from 'react';
import ChevronDownIcon from '@/images/icons/chevron-down.svg';
import {motion} from 'framer-motion';
import CheckboxField from '@/app/components/form/customFields/CheckboxField';

interface FilterItemProps {
    aggregation: SearchAggregation;
    handleFilterChange: () => void;
    initialOpen?: boolean;
}

export default function FilterItem({aggregation, initialOpen, handleFilterChange}: FilterItemProps) {
    const [filterOpen, setFilterOpen] = useState<boolean>(initialOpen || false);

    return (
        <div className="bg-grey-100 rounded-lg overflow-hidden">
            <button onClick={() => setFilterOpen(!filterOpen)}
                    className="flex justify-between w-full items-center px-4 py-3">
                <div className="text-grey-500 small font-semibold">
                    {aggregation.label}
                </div>
                <div className="shrink-0">
                    <ChevronDownIcon className={`w-6 h-6 text-primary-text transition-all ${filterOpen ? 'rotate-180' : ''}`}/>
                </div>
            </button>
            <motion.div className="transform transition-transform origin-top ease-out"
                        initial={{height: 0}}
                        animate={{height: filterOpen ? 'auto' : 0}}
                        transition={{duration: 0.25, ease: 'easeIn'}}>
                <div className="px-4 pb-2">
                    {aggregation.values.map((value) => (
                        <div className="py-3" key={value.value}>
                            <CheckboxField name={aggregation.formName || aggregation.key || ''} label={value.label}
                                           required={false}
                                           onChange={handleFilterChange}
                                           value={value.value}
                                           centeredLabel={true}
                                           key={value.value}/>
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    )
}
