import React, {RefObject, useEffect} from 'react';

interface TimelineClientHelperProps {
    timelineContainerRef: RefObject<HTMLDivElement>;
    setMaxTimelineHeight: (height: number) => void;
    setTimelineBulletHeight: (height: number) => void;
}

export default function TimelineClientHelper({
                                                 timelineContainerRef,
                                                 setMaxTimelineHeight,
                                                 setTimelineBulletHeight,
                                             }: TimelineClientHelperProps) {
    useEffect(() => {
        if (timelineContainerRef.current) {
            const firstThreeElements = timelineContainerRef.current.querySelectorAll(':scope > *:nth-child(-n+3)');

            let totalHeight = 0;

            firstThreeElements.forEach(element => {
                totalHeight += element.clientHeight;
            });

            setMaxTimelineHeight(totalHeight);
            handleScroll();

            window.addEventListener('scroll', () => {
                handleScroll();
            });
        }
    }, [timelineContainerRef?.current]);

    const handleScroll = () => {
        if (timelineContainerRef.current) {
            // @ts-ignore
            const elements: HTMLDivElement[] = Array.from(timelineContainerRef.current.children);
            const windowHeight = window.innerHeight;

            const centerY = windowHeight / 2;

            let closestElement = null;
            let closestDistance = Number.MAX_VALUE;

            let closestIndex = 0;
            let closestElementOffset = 0;

            if (window.scrollY === 0) {
                let closestElement = elements[0];
                closestElementOffset = closestElement.offsetTop + closestElement.getBoundingClientRect().height / 2;
            } else {
                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];
                    const offsetTop = element.offsetTop;

                    const rect = element.getBoundingClientRect();

                    const elementCenterY = rect.top + rect.height / 2;

                    const distance = Math.abs(centerY - elementCenterY);

                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestElement = element;
                        closestIndex = i;
                        closestElementOffset = offsetTop + element.getBoundingClientRect().height / 2;
                    }
                }
            }

            setTimelineBulletHeight(closestElementOffset);
        }
    }

    return <></>;
}