'use client'

import React, {useState} from 'react';
import {PimcoreImage as PimcoreImageType} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';

interface ProductMagnifierImageProps {
    image: PimcoreImageType;
    className?: string;
    withCaption?: boolean;
    withLightbox?: boolean;
    setLightboxOpen?: (open: boolean) => void;
    invertedCaption?: boolean;
}

const zoomLevel = 2.5;
const magnifierHeight = 280;
const magnifierWidth = 280;

export default function ProductMagnifierImage({
                                                  image,
                                                  className,
                                                  withLightbox,
                                                  withCaption,
                                                  setLightboxOpen,
                                                  invertedCaption,
                                              }: ProductMagnifierImageProps) {
    const [showMagnifier, setShowMagnifier] = useState<boolean>(false);
    const [[imgWidth, imgHeight], setSize] = useState<number[]>([0, 0]);
    const [[x, y], setXY] = useState<number[]>([0, 0]);

    const mouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const el = e.currentTarget;

        const {width, height} = el.getBoundingClientRect();
        setSize([width, height]);
        setShowMagnifier(true);
    }

    const mouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setShowMagnifier(false);
    }

    const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const el = e.currentTarget;
        const {top, left} = el.getBoundingClientRect();

        const x = e.pageX - left - window.scrollX;
        const y = e.pageY - top - window.scrollY;

        setXY([x, y]);
    };

    return (
        <div className="relative h-full w-full"
             onMouseEnter={(e) => mouseEnter(e)}
             onMouseLeave={(e) => mouseLeave(e)}
             onMouseMove={(e) => mouseMove(e)}>
            <PimcoreImage image={image}
                          className={className}
                          withLightbox={withLightbox}
                          withCaption={withCaption}
                          invertedCaption={invertedCaption}
                          setLightboxOpen={setLightboxOpen}/>
            <div className="absolute pointer-events-none bg-no-repeat rounded-lg border border-blue-200 bg-white"
                 style={{
                     display: showMagnifier ? '' : 'none',
                     height: `${magnifierHeight}px`,
                     width: `${magnifierWidth}px`,
                     backgroundImage: `url('${image.path}')`,
                     top: `${y - magnifierHeight / 2}px`,
                     left: `${x - magnifierWidth / 2}px`,
                     backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                     backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                     backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                 }}
            />
        </div>
    )
}