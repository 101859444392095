'use client';

import {useEffect, useRef, useState} from 'react';
import {VideoContentElement} from '@/app/components/cms/content/Video/Video.types';
import Plyr from 'plyr';

interface VideoInlineProps {
    element: VideoContentElement;
    autoplay: boolean;
    controls?: boolean;
    cover?: boolean;
    disabled?: boolean;
}

export default function VideoInline({element, autoplay, controls = true, cover = false, disabled = false}: VideoInlineProps) {
    const assetVideoRef = useRef<HTMLVideoElement | null>(null);
    const externalVideoRef = useRef<HTMLDivElement | null>(null);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const plyrConfig = {
        ratio: '16:9',
        controls: [
            'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen',
        ],
        iconUrl: '/assets/lib/plyr.svg',
        autoplay: autoplay,
        hideControls: true,
        resetOnEnd: true,
        vimeo: {
            dnt: true,
            byline: false,
            portrait: false,
            title: false,
            speed: true,
            transparent: false,
        },
        youtube: {
            noCookie: true,
            rel: 0,
            showinfo: 0,
            iv_load_policy: 3,
            modestbranding: 1,
        },
        clickToPlay: !disabled
    }

    const playPauseAssetVideo = () => {
        if (!assetVideoRef.current) return;

        if (isPlaying) {
            assetVideoRef.current.pause();
        } else {
            assetVideoRef.current.play();
        }
    }

    useEffect(() => {
        let plyr: Plyr|null = null;
        if (assetVideoRef.current) {
            plyr = new Plyr(assetVideoRef.current as HTMLVideoElement, plyrConfig);
        }
        return () => {
            plyr && plyr.destroy();
        }
    }, [assetVideoRef]);

    useEffect(() => {
        let plyr: Plyr|null = null;
        if (externalVideoRef.current) {
            plyr = new Plyr(externalVideoRef.current as HTMLDivElement, plyrConfig);
        }
        return () => {
            plyr && plyr.destroy();
        }
    }, [externalVideoRef]);

    if (element?.elementContext?.data.video.videoType === 'asset' && element?.elementContext.data.video.videoPath) {
        return (
            <div className={`h-full w-full relative ${!controls && 'hide-controls'}`}>
                <video ref={assetVideoRef}
                       data-poster={element.elementContext.data.video.posterThumbnail?.path}
                       playsInline
                       controls={controls}
                       className={`${cover ? 'object-cover h-full object-center' : ''}`}
                       onPlay={() => setIsPlaying(true)}
                       onPause={() => setIsPlaying(false)}>
                    <source src={element.elementContext.data.video.videoPath} type="video/mp4"/>
                </video>

                {(!controls && !disabled) && (
                    <button
                        className="u-center absolute inset-0"
                        onClick={playPauseAssetVideo}>
                    </button>
                )}
            </div>

        )
    } else {
        return (
            <div ref={externalVideoRef}
                 data-poster={element.elementContext.data.video.posterThumbnail?.path}
                 data-plyr-provider={element.elementContext.data.video.videoType}
                 data-plyr-embed-id={element.elementContext.data.video.videoId}
                 className={cover ? 'object-cover h-full object-center' : ''}
            ></div>
        )
    }
}
