'use client'
import ShapeTeaser, {ShapeContentType, ShapeTeaserElement, ShapeType} from '@/app/components/util/ShapeTeaser/ShapeTeaser';
import Link from 'next/link';
import RelatedNewsItem from '@/app/components/news/detail/relatedNews/RelatedNewsItem';
import {NewsTeaserEntry} from '@/types/objects/news';
import {PimcoreLink} from '@/types/pimcore';
import {useState} from 'react';
import {themeSmartTec} from '@/config/constants';

interface NewsTeaserProps {
    title: string;
    shapeTeaser: ShapeTeaserElement | null;
    newsTeasers: NewsTeaserEntry[];
    link: PimcoreLink | null;
    theme: string;
}

export default function NewsTeaser({title, shapeTeaser, newsTeasers, link, theme}: NewsTeaserProps) {
    const [visibleNewsItem, setVisibleNewsItem] = useState<number>(0);
    const shapeTeaserType = theme === themeSmartTec ? ShapeType.rfid : ShapeType.rectangle

    return (
        <div className="toolbox-element toolbox-news-teaser grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-24"
             onMouseLeave={() => setVisibleNewsItem(0)}>

            <div className="relative mx-auto w-[327px] max-w-full sm:w-7/12 md:w-full">
                <div
                    className={`relative left-0 top-0 w-full h-auto transition duration-300 ${visibleNewsItem === 0 ? 'opacity-100' : 'opacity-0'}`}>
                    <ShapeTeaser element={shapeTeaser ?? {
                        type: ShapeContentType.flat,
                        image: null,
                        video: null
                    } as ShapeTeaserElement} shapeType={shapeTeaserType}/>
                </div>
                {newsTeasers.map(nt =>
                    <div key={nt.id}
                         className={`absolute left-0 top-0 w-full h-auto transition duration-300 ${nt.id === visibleNewsItem ? 'opacity-100' : 'opacity-0'}`}>
                        <ShapeTeaser
                            element={{
                                type: ShapeContentType.image,
                                image: nt.teaserImage,
                                video: null
                            } as ShapeTeaserElement}
                            shapeType={shapeTeaserType}/>
                    </div>
                )}
            </div>

            <div>
                {title && (
                    <h2>{title}</h2>
                )}

                <div className="mb-3 sm:my-3 lg:my-12 flex flex-col gap-6">
                    {newsTeasers.map(nt =>
                        <div key={nt.id}
                             onMouseEnter={() => setVisibleNewsItem(nt.id)}>
                            <RelatedNewsItem data={nt}/>
                        </div>
                    )}
                </div>

                {link && link.path && (
                    <Link href={link.path}
                          target={link.target}
                          title={link.title}
                          className="btn btn-secondary">
                        {link.text}
                    </Link>
                )}
            </div>

        </div>
    )

}
