'use client';

import {useState} from 'react';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import Lightbox from '@/app/components/util/Lightbox/Lightbox';
import {GalleryElement} from '@/app/components/cms/content/VideoGallery/VideoGallery.types';
import {PimcoreImage as PimcoreImageInterface, PimcoreVideoElement} from '@/types/pimcore';
import VideoGallerySlider from '@/app/components/cms/content/VideoGallery/VideoGallerySlider';
import GalleryVideoItem from '@/app/components/cms/content/VideoGallery/partial/GalleryVideoItem';

interface GalleryTilesProps {
    elements: Array<GalleryElement>;
    withLightbox?: boolean;
    playVideosInOverview?: boolean;
}

export default function VideoGalleryTiles({elements, withLightbox, playVideosInOverview}: GalleryTilesProps) {
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
    const [lightboxImage, setLightboxImage] = useState<number>(-1);

    const openLightbox = (index: number) => {
        setLightboxImage(index);
        setLightboxOpen(true);
    }

    const closeLightbox = () => {
        setLightboxImage(-1);
        setLightboxOpen(false);
    }

    return (
        <div className="gap-x-6 columns-1 ty:columns-2 md:columns-3 lg:columns-4">
            {elements?.length > 0 && (
                <>
                {elements.map((image, index) => (
                    <div key={index}
                         className={`w-full h-auto pt-6 ${withLightbox ? 'cursor-pointer' : ''}`}
                         onClick={() => withLightbox ? openLightbox(index) : null}>

                        {image.type === 'image' && (
                            <PimcoreImage image={image.gallery as PimcoreImageInterface} className="w-full rounded-lg h-auto"/>
                        )}

                        {image.type === 'video' && (
                            <GalleryVideoItem element={image.gallery as PimcoreVideoElement} className="w-full rounded-lg h-auto"
                                              playVideosInOverview={playVideosInOverview}/>
                        )}

                    </div>
                ))}

                    {withLightbox && (
                        <ClientOnly>
                            <Lightbox open={lightboxOpen} setOpen={closeLightbox}>
                                <div className="flex w-full h-full items-center justify-center">
                                    <div className="container max-h-4/5 flex flex-col justify-center max-w-4xl">
                                        <VideoGallerySlider forceSliderButtons={true} elements={elements} startSlide={lightboxImage} />
                                    </div>
                                </div>
                            </Lightbox>
                        </ClientOnly>
                    )}
                </>
            )}
        </div>
    )
}
