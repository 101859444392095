'use client';

import {TimelineItem} from '@/app/components/cms/customComponents/Timeline/Timeline.types';
import {useRef, useState} from 'react';
import TimelineClientHelper from '@/app/components/cms/customComponents/Timeline/TimelineClientHelper';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import useTranslations from '@/hooks/translations';

interface TimelineItemsProps {
    items: TimelineItem[];
}

export default function TimelineItems({items}: TimelineItemsProps) {
    const {tc} = useTranslations();
    const [timelineExpanded, setTimelineExpanded] = useState<boolean>(items.length < 4);
    const [maxTimelightHeight, setMaxTimelineHeight] = useState<number>(0);
    const [timelineBulletHeight, setTimelineBulletHeight] = useState<number>(0);
    const timelineContainer = useRef<HTMLDivElement>(null);

    const handleTimelineBulletHeightUpdate = (h: number) => {
        if (h !== timelineBulletHeight) {
            setTimelineBulletHeight(h);
        }
    }

    return (
        <div className={`w-full overflow-hidden relative`}>
            <ClientOnly>
                <TimelineClientHelper timelineContainerRef={timelineContainer}
                                      setTimelineBulletHeight={handleTimelineBulletHeightUpdate}
                                      setMaxTimelineHeight={setMaxTimelineHeight}/>
            </ClientOnly>
            <div className="relative w-full flex flex-col gap-4 py-12 lg:py-24" ref={timelineContainer}
                 style={timelineExpanded ? {} : {
                     maxHeight: `${maxTimelightHeight}px`,
                 }}>
                {items.map((item, index) => (
                    <div key={index} className={`timeline-item md:flex ${index % 2 === 0 ? 'md:justify-start' : 'md:justify-end'}`}>
                        <div className={`w-full pl-8 md:w-1/2 ${index % 2 === 0 ? 'md:pr-18 md:pl-0' : 'md:pl-18'}`}>
                            <div className="relative block rounded-lg u-border-gradient-landscape">
                                <div className="p-6 flex flex-col gap-4">
                                    {item.image ? (
                                        <PimcoreImage image={item.image} className="w-full h-auto rounded-lg"/>
                                    ) : <></>}
                                    {item.year ? (
                                        <h4 className="mb-0">{item.year}</h4>
                                    ) : <></>}
                                    {item.text ? (
                                        <div>{item.text}</div>
                                    ) : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="absolute h-full left-2 md:left-1/2 top-0 w-px bg-grey-200">
                <div className="w-px bg-primary flex flex-col items-center transition-all duration-700 ease-in-out" style={{
                    height: `${timelineBulletHeight}px`,
                }}>
                    <div className="flex-1"></div>
                    <div className="shrink-0 h-4 w-4 bg-primary rounded-full"></div>
                </div>
            </div>
            {!timelineExpanded ? (
                <div className="absolute bottom-0 left-0 w-full pt-12 pb-6 flex items-center justify-center bg-gradient-to-t from-white to-transparent">
                    <button type="button" className="btn btn-primary" onClick={() => setTimelineExpanded(true)}>
                        {tc('app.timeline.show_timeline')}
                    </button>
                </div>
            ) : <></>}
        </div>
    )
}